import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';

const StyledGallery = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3.75rem;

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    flex-direction: row;
    margin-top: -2rem;
  }

  a {
    text-decoration: none;
    color: ${prop('theme.colors.black')};

    &:hover {
      text-decoration: none;
    }
  }
`;

const Gallery = ({ children }) => <StyledGallery>{children}</StyledGallery>;

export default Gallery;
