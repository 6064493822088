import styled, { css } from 'styled-components';
import { prop, switchProp } from 'styled-tools';

const HeroCTA = styled.a`
  border: 0.125rem solid ${prop('theme.colors.white')};
  box-shadow: -1rem 0.5625rem 0.8125rem 0 rgba(0, 0, 0, 0.19);
  display: inline-block;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
  margin: 0 auto 0.5rem;
  width: fit-content;

  ${switchProp('$slideId', {
    image19: css`
      background: #ff0c0c;
      border-radius: 0.25rem;
      
      @media (max-width: ${prop('theme.breakpoints.sm')}) {
        margin-left: 2rem;
      }
    `,
    image20: css`
      background: #ff0c0c;
      border: none;
    `,
    image21: css`
      background: ${prop('theme.colors.lightRed')};
    `,
})}

  &:hover {
    background: ${prop('theme.colors.primaryBlack')};
    cursor: pointer;
  }

  > div {
    color: ${prop('theme.colors.white')};
    font-family: roboto, sans-serif;
    font-size: 0.9em;
    font-weight: 600;
    line-height: 1em;
    padding: 0.5rem 2.25rem;
    text-align: center;

    @media (min-width: ${prop('theme.breakpoints.sm')}) {
      font-size: 0.8em;
      font-weight: 600;
    }

    ${switchProp('$slideId', {
      image19: css`
        padding: 0.75rem 1rem;
      `,
      image20: css`
        font-weight: 500;
        padding: 0.5rem 2rem;

        @media (min-width: ${prop('theme.breakpoints.xl')}) {
          padding: 0.5rem 2.5rem;
        }
      `,
      image21: css`
        padding: 0.25rem 2rem;

        @media (max-width: ${prop('theme.breakpoints.sm')}) {
          font-size: 1.1em;
        }
     `,
})}

    br {
      display: none;

      @media (min-width: ${prop('theme.breakpoints.sm')}) {
        display: block;
      }
    }
  }
`;

export default HeroCTA;
