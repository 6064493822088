import { prop } from 'styled-tools';
import styled from 'styled-components';

const TastyImageContainer = styled.div`
  margin: 0 auto;
  max-width: 16.75rem;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    display: flex;
    height: 9.375rem;
  }

  img {
    width: 100%;
  }
`;

export default TastyImageContainer;
