import React from 'react';
import styled from 'styled-components';
import { Col as BaseCol, Row } from 'reactstrap';
import inspirationIcon from 'components/Home/assets/tech-Icon-Inspiration.png';
import lightningIcon from 'components/Home/assets/tech-Icon-quick-roll.png';
import bullseyeIcon from 'components/Home/assets/tech-Icon-bullseye.png';
import toolIcon from 'components/Home/assets/tech-Icon-tool.png';
import pyramidIcon from 'components/Home/assets/tech-Icon-pyramid.png';
import curveIcon from 'components/Home/assets/tech-Icon-curve.png';
import { theme } from 'styled-tools';

const Col = styled(BaseCol)`
  color: ${theme('colors.darkGray')};
  text-align: center;

  @media (max-width: 991px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 17.5rem;
  }

  .technology-icon {
    height: 5.25rem
  }
`;

TechnologyFeatures.propTypes = {

};

function TechnologyFeatures(props) {
  return (
    <Row>
      <Col xs={12} md={4}>
        <img alt="wrench icon" className="technology-icon" src={inspirationIcon} />
        <h4>In-App Trading Inspiration</h4>
        <p>
          Kickstart your trade ideas.
        </p>
      </Col>
      <Col xs={12} md={4}>
        <img alt="heart icon" className="technology-icon" src={lightningIcon} />
        <h4>Quick Roll</h4>
        <p>
          Roll your options position with one click.
        </p>
      </Col>
      <Col xs={12} md={4}>
        <img alt="pencil icon" className="technology-icon" src={bullseyeIcon} />
        <h4>Percent of Profit Limit Orders</h4>
        <p>
          Use profit targets to increase your odds.
        </p>
      </Col>
      <Col xs={12} md={4}>
        <img alt="links icon" className="technology-icon" src={toolIcon} />
        <h4>Quick Order Adjustments</h4>
        <p>
          Adjust your orders with speed.
        </p>
      </Col>
      <Col xs={12} md={4}>
        <img alt="paper plane icon" className="technology-icon" src={pyramidIcon} />
        <h4>ETF-Equivalent Futures Delta</h4>
        <p>
          Understand your risk without the complexity.
        </p>
      </Col>
      <Col xs={12} md={4}>
        <img alt="external link icon" className="technology-icon" src={curveIcon} />
        <h4>Curve Analysis</h4>
        <p>
          Visualize your trades during setup.
        </p>
      </Col>
    </Row>
  );
}

export default TechnologyFeatures;
