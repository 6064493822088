import React from 'react';
import SmallPrint from 'components/SmallPrint';
import StyledPricing from 'components/StyledPricing';
import StyledPricingTable from 'components/StyledPricingTable';

function AbbreviatedPricingTable() {
  return (
    <>
      <StyledPricingTable>
        <thead>
        <tr>
          <th />
          <th>Opening Commission*</th>
          <th>Closing Commission*</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Stock &amp; ETFs</td>
          <td className='zero-cell'>
            <StyledPricing>
              $0
              <span>unlimited shares</span>
            </StyledPricing>
          </td>
          <td className='zero-cell'>
            <StyledPricing>$0</StyledPricing>
          </td>
        </tr>
        <tr>
          <td>
            Options on
            <br />
            Stock & ETFs
            <span>($10 max per leg)</span>
          </td>
          <td>
            <StyledPricing>
              $1.00
              <span>
                per contract <br />
                ($10 max per leg)
              </span>
            </StyledPricing>
          </td>
          <td className='zero-cell'>
            <StyledPricing>$0</StyledPricing>
          </td>
        </tr>
        <tr>
          <td>
            Options on
            <br />
            Futures
          </td>
          <td>
            <StyledPricing>
              $2.50
              <span>per contract</span>
            </StyledPricing>
          </td>
          <td className='zero-cell'>
            <StyledPricing>$0</StyledPricing>
          </td>
        </tr>
        </tbody>
      </StyledPricingTable>
      <SmallPrint>* All prices are shown in U.S. Dollars</SmallPrint>
      <SmallPrint>
        Applicable exchange, clearing, and regulatory fees still apply
        to all opening and closing trades except for cryptocurrency orders
        which are not subject to exchange, clearing, and regulatory fees.
      </SmallPrint>
    </>
  );
}

export default AbbreviatedPricingTable;