import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, prop, switchProp } from 'styled-tools';
import externalLinks from 'utils/externalLinks';
import { Container } from 'reactstrap';
import violatorImg from 'images/hero/TT1402_TW_Violator_Web.png';
import HeroCTA from './HeroCTA';

const ViolatorImg = styled.a`
  && {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 40.25rem;
    margin: 0 auto;

    ${switchProp('$display', {
      mobile: css`
        display: flex;
        margin: 0 auto;
        max-width: 97%;
        padding-left: 5px; // account for shadow being part of image and offset to the right.
        padding-top: 1rem;
        padding-bottom: 1rem;

        @media (min-width: ${prop('theme.breakpoints.xs')}) {
          max-width: 95%;
        }

        @media (min-width: ${prop('theme.breakpoints.sm')}) {
          max-width: 30rem;
        }

        @media (min-width: ${prop('theme.breakpoints.md')}) {
          display: none;
        }
      `,
      desktop: css`
        display: none;

        @media (max-width: 900px) {
          max-width: 32rem;
        }

        @media (min-width: ${prop('theme.breakpoints.md')}) {
          display: flex;
          width: 100%;
          padding-top: 0.875rem;
          padding-bottom: 0.25rem;
          padding-left: 5px;
        }

        @media (min-width: 2100px) {
          padding-bottom: 1.5rem;
        }

        img {
          max-width: 100%;
        }
      `,
    })}
  }

  img {
    max-width: inherit;
  }
`;

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    flex-direction: column;
    margin: 0rem auto;
    max-width: 38.25rem;
    justify-content: center;
    height: 94%;

    ${ifProp({$slideId: 'image20'}, css`
      max-width: 39.25rem;
    `)}

    ${ifProp({ $slideId: 'image21' }, css`
      max-width: 41.25rem;
    `)}
  }

  @media (max-width: ${prop('theme.breakpoints.xs')}) {
    font-size: 1.4375rem;
  }

  @media (max-width: 375px) {
    font-size: 1.3125rem;
  }

  @media (max-width: 345px) {
    font-size: 1.25rem;
  }
`;

const HeroDisclaimer = styled.div`
  font-family: roboto, sans-serif;
  font-weight: 300;
  text-align: left;

  ${switchProp('$slideId', {
    image19: css`
      padding-left: 2rem;
      color: ${prop('theme.colors.lighterNavyBlue')};
      font-size: 5.6px;

      @media (min-width: ${prop('theme.breakpoints.sm')}) {
        font-size: 7px;
      }
    `,
    image20: css`
      color: ${prop('theme.colors.black')};
      font-family: heebo, sans-serif;
      font-size: .7rem;
      font-weight: 700;
      line-height: 1.2;

      @media (min-width: ${prop('theme.breakpoints.sm')}) {
        font-size: 0.75rem;
        margin-right: 0.75rem;
      }

      @media (min-width: ${prop('theme.breakpoints.md')}) {
        font-size: 0.8125rem;
      }

      @media (min-width: ${prop('theme.breakpoints.lg')}) {
        font-size: .875rem;
        margin-right: 2rem;
      }

      @media (max-width: ${prop('theme.breakpoints.xxs')}) {
        margin-right: 0.75rem;
      }
    `,
    image21: css`
      text-align: center;
      color: ${prop('theme.colors.smokeGray')};
      font-size: 0.5rem;
    `,
  })}

  && {
    a {
      color: ${prop('theme.colors.black')};
      display: inline-block;
      font-family: heebo, sans-serif;
      font-weight: 600;
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const HeroLogo = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 0 1rem;

  ${switchProp('$display', {
    mobile: css`
      display: flex;
      margin: 4rem auto 0;
      max-width: 95%;

      @media (min-width: ${prop('theme.breakpoints.sm')}) {
        display: none;
      }

      ${switchProp('$slideId', {
        image19: css`
          margin: 0 auto;
          margin-left: 2rem;
          max-width: 100%;
          width: 80%;
        `,
        image21: css`
          max-width: 85%;
        `
    })}
    `,
    desktop: css`
      display: none;

      ${switchProp('$slideId', {
        image19: css`
          margin-left: 2rem;

          &&& {
            width: 26rem;
          }
        `
      })}

      @media (min-width: ${prop('theme.breakpoints.sm')}) {
        display: flex;
        width: 100%;
        padding-bottom: 0.5rem;
      }
    `,
  })}

  img {
    max-width: inherit;
    width: 100%;
  }
`;

const HeroText = styled.h2`
  ${switchProp('$slideId', {
    image19: css`
      font-family: 'Monda', sans-serif;
      font-weight: 700;
      margin-bottom: 2rem;
      text-align: center;

      @media (max-width: ${prop('theme.breakpoints.sm')}) {
        padding: 0 1rem;
      }

      && {
        font-size: 1.8em;

        @media (max-width: ${prop('theme.breakpoints.md')}) {
          font-size: 1.6em;
        }

        @media (max-width: ${prop('theme.breakpoints.sm')}) {
          font-size: 1.7em;
        }

        @media (max-width: 306px) {
          font-size: 1.5em;
        }
      }
    `,
    image20: css`
      color: ${props => props.theme.colors.white};
      font-family: roboto, sans-serif;
      font-style: italic;
      text-align: center;

      @media (max-width: 300px) {
        margin-bottom: 0;
      }

      && {
        font-size: 4rem;
        line-height: 1;

        @media (max-width: 900px) {
          font-size: 1.9em;
          margin-top: 0;
        }

        @media (max-width: 1200px) {
          font-size: 3.5rem;
          margin-top: 0;
        }

        @media (max-width: 1290px) {
          font-size: 3.5rem;
          margin-top: 0;
        }

        @media (max-width: 768px) {
          font-size: 3.5rem;
        }

        @media (max-width: 600px) {
          font-size: 4rem;
          margin-top: 7rem;
        }


        @media (max-width: 475px) {
          font-size: 3rem;
          margin-top: 5rem;
        }

        span {
          &:last-child {
            font-size: 1.2em;

            @media (max-width: 306px) {
              font-size: 1.1em;
            }
          }
        }
      }
    `,
  })}
`;

const HeroSubtext = styled.div`
  font-weight: 300;
  line-height: 1.1em;
  margin-bottom: 1.5rem;

  ${switchProp('$slideId', {
    image19: css`
      margin: 0 auto;
      margin-bottom: 2.5rem;
      text-align: center;
      font-family: 'Monda', sans-serif;
      text-transform: uppercase;
      font-size: 1.25rem;
      letter-spacing: 0.03rem;
      margin-top: 0.5rem;

      @media (min-width: ${prop('theme.breakpoints.sm')}) {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
      }

      br {
        @media (max-width: ${prop('theme.breakpoints.xxs')}) {
          display: none;
        }
      }
    `,
    image20: css`
      color: ${props => props.theme.colors.white};
      font-family: roboto, sans-serif;
      font-size: 1.75rem;
      font-weight: 100;
      line-height: normal;
      margin-bottom: 2rem;
      margin-top: 0.5rem;
      text-align: center;

      @media (min-width: ${prop('theme.breakpoints.sm')}) {
        font-size: 1.25rem;
      }

      @media (max-width: ${prop('theme.breakpoints.xs')}) {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }

      @media (max-width: ${prop('theme.breakpoints.xxs')}) {
        font-size: 1.25rem;
      }

      @media (min-width: ${prop('theme.breakpoints.md')}) {
        margin-bottom: 1rem;
      }
    `,
    image21: css`
      margin: 0 auto;
      text-align: center;
      font-family: Roboto Condensed, sans-serif;
      font-size: 1.65rem;
      font-weight: bold;
      margin-bottom: 2rem;
      max-width: 90%;

      @media (min-width: ${prop('theme.breakpoints.sm')}) {
        font-size: 2.8rem;
        max-width: 100%;
      }
    `
  })}
`;

const Footnote = styled.sup`
  font-weight: 300;
  top: -0.45em;

  ${ifProp('$subtext', css`
    color: ${prop('theme.colors.mustardYellow')};
  `)}

  ${switchProp('$slideId', {
    image19: css`
      color: ${prop('theme.colors.primaryRed')};
    `
  })}
`;

const StyledHeroCta = styled.a`
  background-color: ${prop('theme.buttons.primary.backgroundColor')};
  color: ${prop('theme.colors.white')};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5rem;
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 3.3rem;
  width: 11.2rem;


  &:hover {
    background-color: rgb(32, 37, 37);
    color: ${prop('theme.colors.white')};
  }

  > div {
    justify-content: center;
    display: flex;
    font-size: 1.3rem;
    font-weight: 600;

    @media (min-width: ${prop('theme.breakpoints.lg')}) {
      font-size: 1.5rem;
    }
  }
`;

const BannerContainer = styled.div`

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    margin: -0.5rem auto 0;
    max-width: 23rem;
    padding: 0 1rem;
  }

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    max-width: 25rem;
  }

  @media (min-width: 901px) {
    margin: -1.5rem auto 0;
    max-width: 37rem;
  }

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }

  @media (max-width: ${prop('theme.breakpoints.xxs')}) {
    padding: 0 1rem;
  }

  ${switchProp('$slideId', {
    image21: css`
      @media (min-width: 901px) {
        margin: 0 auto;
      }
    `
  })}
`;

const BlueText = styled.span`
  color: ${prop('theme.colors.cyanBlue')};
`;

const GreenText = styled.span`
  color: ${prop('theme.colors.brightGreen')};
`;

const TextContainer = styled(Container)`
  @media (min-width: ${prop('theme.breakpoints.md')}) {
    max-width: 435px !important;
  }
`;

const StyledSup = styled.sup`
  top: -0.4em;
`;

const SmallText = styled.span`
  font-size: 0.80rem;
  display: flex;
  text-align: center;
  justify-content: center;
  color: black;

  @media (max-width: ${prop('theme.breakpoints.xs')}) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
`;

const LineBreak = styled.span`
  display: block;

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    display: inline;
  }
`;

const RedText = styled.span`
  color: ${prop('theme.colors.primaryRed')};
`;

const StyledContainer = styled(Container)`
  max-width: 509px !important;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    max-width: 377px !important;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  color: black;
  font-size: 0.85rem;
`;

const propTypes = {
  image: PropTypes.object
}

function HeroRotateInner({ image }) {
  if (!image) {
    return null;
  }

  return (
    <>
      {/*Keeping this code in case the violatorImg is used again*/}
      {/*<ViolatorImg $display='desktop' href={externalLinks.bestOnlineBroker2021} target='_blank'>*/}
        {/*<img src={violatorImg} alt='Named Best Online Broker in America by IBD!' />*/}
      {/*</ViolatorImg>*/}
      {/*<ViolatorImg $display='mobile' href={externalLinks.bestOnlineBroker2021} target='_blank'>*/}
        {/*<img src={violatorImg} alt='Named Best Online Broker in America by IBD!' />*/}
      {/*</ViolatorImg>*/}
      <HeroContainer $slideId={image.id}>
        {image.id === 'image19' && (
          <HeroText $slideId={image.id}>
            Stock. Options. Futures. Crypto. One Platform.
          </HeroText>
        )}
        {image.desktopLogoSrc && (
          <HeroLogo $display='desktop' $slideId={image.id}>
            <img src={image.desktopLogoSrc} alt={image.logoAlt} />
          </HeroLogo>
        )}
        {image.mobileLogoSrc && (
          <HeroLogo $display='mobile' $slideId={image.id}>
            <img src={image.mobileLogoSrc} alt={image.logoAlt} />
          </HeroLogo>
        )}
          <HeroSubtext $slideId={image.id}>
            {image.id === 'image19' && (
              <>Trade as you are.</>
            )}
            {image.id === 'image21' && (
              <>
                Get <BlueText>10</BlueText> Options <LineBreak>or <GreenText>100</GreenText> Shares</LineBreak>
                <SmallText>when you fund your account today.*</SmallText>
              </>
            )}
          </HeroSubtext>
        <BannerContainer $slideId={image.id}>
          <HeroDisclaimer $slideId={image.id}>
            {image.id === 'image21' && (
              <>
                <RedText>
                  <Footnote>*</Footnote>
                  Available to new customers only. Minimum deposit of  USD 2,000 required. See full T&Cs at tastytrade.com/10or100
                </RedText>
                <Wrapper>
                  Options, futures, and futures options are complex products and may
                  expose investors to potentially rapid and substantial losses.&nbsp;
                  Margin loans can amplify losses.
                </Wrapper>
              </>
            )}
          </HeroDisclaimer>
          {/*{image.id === 'image20' && (*/}
          {/*  <StyledHeroCta*/}
          {/*    href={externalLinks.bonusBroker}*/}
          {/*    rel='noopener noreferrer'*/}
          {/*    $slideId={image.id}*/}
          {/*    target='_blank'*/}
          {/*    aria-label={image.ctaAria}*/}
          {/*   >*/}
          {/*  Check us out*/}
          {/*</StyledHeroCta>*/}
          {/*)}*/}
        </BannerContainer>
        {/*{image.id === 'image20' && (*/}
        {/*  <>*/}
        {/*    <StyledContainer>*/}
        {/*      <SmallText>*/}
        {/*        tastytrade, Inc. (previously known as tastyworks, Inc.) is a*/}
        {/*        registered broker-dealer and member of FINRA, NFA, and SIPC.*/}
        {/*      </SmallText>*/}
        {/*    </StyledContainer>*/}
        {/*  </>*/}
        {/*)}*/}
      </HeroContainer>
    </>
  )
};

export default HeroRotateInner;
