import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import get from 'lodash.get';
import { ifProp, prop, switchProp } from 'styled-tools';
import HeroRotateInner from 'components/HeroRotateInner';
import { useLayoutContext } from 'context/LayoutContext';
import Hero from 'components/Hero';

const HomepageHeroContainer = styled.div`
  font-size: 1.65em;
  position: relative;
  overflow: hidden;

  ${ifProp('mobileBackground', css`
    background-image: url(${prop('mobileBackground')});
    background-size: cover;
    background-repeat: no-repeat;
  `)}

  @media (min-width: ${prop('theme.breakpoints.xs')}) {
    font-size: 1.75em;
  }

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    ${ifProp('desktopBackground', css`
      background-image: url(${prop('desktopBackground')});
      background-position: right;
    `)}
  }

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    background-position: initial;
  }

  a {
    display: block;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  video {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    height: auto;
    right: 0;
    width: auto;
    z-index: 0;

    @media (max-width: ${prop('theme.breakpoints.sm')}) {
      width: 100%;
    }

    @media (min-width: ${prop('theme.breakpoints.sm')}) {
      top: -10px;
    }

    @media (min-width: ${prop('theme.breakpoints.sm')}) and (max-width: ${prop('theme.breakpoints.xl')}) {
      height: 100%;
    }

    &::-webkit-media-controls-fullscreen-button,
    &::-webkit-media-controls-play-button,
    &::-webkit-media-controls-pausebutton {
      display: none;
    }
  }
`;

function HomepageHero({ activeHero }) {
  const { state: layoutContextState } = useLayoutContext();
  const { showAlert } = layoutContextState;

  return (
    <HomepageHeroContainer>
      <Hero
        heroType='mobile'
        heroImage={get(activeHero, 'desktopSrc')}
        hideGradient
        mobileHero={get(activeHero, 'mobileSrc')}
        tabletHero={get(activeHero, 'tabletSrc')}
        $showAlert={showAlert}
        page='homepage'
      >
        <HeroRotateInner image={activeHero} />
      </Hero>
    </HomepageHeroContainer>
  )
};

export default HomepageHero;
