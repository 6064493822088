import React, { useEffect, useState } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCaretUp,
  faCaretDown,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import get from 'lodash.get';
import styled, { css } from 'styled-components';
import { prop, switchProp } from 'styled-tools';
import { Row, Col, Container } from 'reactstrap';
import SEO from 'components/SEO';
import Layout from 'components/Layout';
import Gallery from 'components/Gallery';
import GalleryCard from 'components/GalleryCard';
import cardImage1 from 'images/toolbox-Active.jpg';
import cardImage2 from 'images/GFX-ebook-default.png';
import cardImage3 from 'images/tw-25-cryptos.jpg';
import cardImage2Hover from 'images/GFX-ebook-hover.png';
import cardImage1Hover from 'images/toolbox-Hover.jpg';
import cardImage3Hover from 'images/tw-25-cryptos-hover.jpg';
import tastyliveLogo from 'images/tastylive.svg'
import pricingIcon from 'components/Home/assets/pricing-icon-button.png';
import technologyIcon from 'components/Home/assets/tech-icon-button.png';
import educationIcon from 'components/Home/assets/education-icon-button.png';
import { groupSeven } from 'utils/carouselData';
import TastyImageContainer from 'components/Home/styled/TastyImageContainer';
import TastyButton from 'components/TastyButton';
import TechnologyFeatures from 'components/Home/TechnologyFeatures';
import EducationFeatures from 'components/Home/EducationFeatures';
import HeroRotateInner from 'components/HeroRotateInner';
import tastyworksLogo from '../images/tastytrade.svg';
import ausFlagIconImg from '../images/logo/australia-flag-icon.png';
import usFlagIconImg from '../images/logo/usa-flag-icon.png'
import externalLinks from 'utils/externalLinks';
import AbbreviatedPricingTable from 'components/AbbreviatedPricingTable';
import ExternalLink from 'components/ExternalLink';
import HomepageHero from 'components/HomepageHero';
import SmallPrint from 'components/SmallPrint';

library.add(faCaretUp, faCaretDown, faCircle);

const HomeSection = styled(Container)`
  &&& {
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      padding-left: 6rem;
      padding-right: 6rem;
    }
  }
`;

const StyledRow = styled(Row)`
  justify-content: space-around;
`;

const TastyContainerTitle = styled.p`
  font-weight: 700;
`;

const TastyContainerText = styled.p`
  text-align: left;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-left: auto;
    margin-right: auto;
    max-width: 21.25rem;
  }

  ${switchProp('$type', {
    body: css`
      font-size: .75rem;
    `
  })}
`;

const FullBleedSection = styled.section`
  ${switchProp('variant', {
    light: css`
      background: ${prop('theme.colors.lighterGray')};
    `,
  }, css`
    background: ${prop('theme.colors.secondaryBlack')};
  `)}
`;

const FlagIcon = styled.img`
  height: 1.5rem;
  margin-right: 0.5rem;
`;

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const EducationButton = styled(TastyButton)`
  margin-top: 2rem;
`;

const TastyContainerSubtitle = styled.p`
  font-size: 0.875rem;
  margin-bottom: 0;

  &:first-of-type {
    margin-right: 1rem;
  }
`;

const SectionIcon = styled.img`
  display: block;
  margin: 0 auto 1rem;
  max-width: 13.75rem;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin: auto;
    max-width: 100%;
    min-width: 9.375rem;
  }
`;

const StyledText = styled.p`
  text-align: center;
`;

const StyledTitle = styled.h2`
  text-align: center;
`;

function IndexPage({ showPromoBar }) {
  const [heroIndex, setHeroIndex] = useState();

  useEffect(() => {
    // Randomization to determine which banner displays on the homepage.
    const index = Math.floor(Math.random() * groupSeven.length);
    setHeroIndex(index);
  }, [])

  const heroes = groupSeven;
  const activeHero = heroes[heroIndex];

  return (
    <Layout hideBackground>
      <SEO
        title='Home'
        description='Trade options, stock, and futures at one of the premiere brokerage firms in the industry. From the brains that brought you tastylive.'
        canonicalHref='https://www.tastyworks.com.au/'
      />
      <main>
        <HomepageHero activeHero={activeHero} />
        <Container>
          <Gallery>
            <GalleryCard
              image={cardImage1}
              hoverImage={cardImage1Hover}
              to='/education'
              header='BUILD YOUR TOOLBOX'
              subheader='Refine your trading skills with free daily content.'
            />
            <GalleryCard
              image={cardImage2}
              hoverImage={cardImage2Hover}
              href={externalLinks.tastyliveLearningCenter}
              header='NEW TO TRADING? START YOUR JOURNEY HERE'
              subheader='Learn how to control your odds!'
              target='_blank'
            />
            <GalleryCard
              image={cardImage3}
              hoverImage={cardImage3Hover}
              href={externalLinks.coinBible}
              header='CRYPTO TRADING'
              subheader='Trade crypto alongside stocks, options, and futures—all in one place.'
              target='_blank'
            />
          </Gallery>
        </Container>
        <HomeSection className='my-5'>
          <StyledTitle className='text-uppercase'>
            About Us
          </StyledTitle>
          <StyledText>
            We believe in do-it-yourself investing. We think controlling your own money can be more rewarding than
            relying on money managers or robo-advisors who put your portfolio on autopilot.
          </StyledText>
          <StyledText>
            That&apos;s why tastytrade gives you every edge possible with the technology, rates, and support that help you
            succeed more easily on your own. We&apos;re as serious about your money as you are, and we work for you who are
            brave enough to take your finances into your own hands.
          </StyledText>
        </HomeSection>
        <FullBleedSection className='my-5 py-5'>
          <HomeSection>
            <StyledRow>
              <Col xs={12} md={6} lg={5} xl={4}>
                <TastyImageContainer style={{ padding: '2.5rem 1.5rem 1.75rem' }}>
                  <img alt="tastylive - a real financial network" src={tastyliveLogo} />
                </TastyImageContainer>
                <div className="text-white text-center">
                  <TastyContainerTitle>Online Financial Content Network</TastyContainerTitle>
                  <p>Launched 2011</p>
                  <TastyContainerText $type='body'>
                    Founded by real traders, tasty<em>live</em> provides hundreds of educational
                    resources, actionable trade ideas, and free live and on-demand
                    programming to help investors do more with their money.
                  </TastyContainerText>
                </div>
              </Col>
              <Col xs={12} md={6} lg={5} xl={4}>
                <TastyImageContainer style={{ padding: '2.5rem 0.5rem 1.75rem' }}>
                  <img src={tastyworksLogo} alt='tastytrade Logo' />
                </TastyImageContainer>
                <div className="text-white text-center">
                  <TastyContainerTitle>Registered Broker-Dealer</TastyContainerTitle>
                  <IconContainer>
                    <FlagIcon alt='American National Flag' src={usFlagIconImg} />
                    <TastyContainerSubtitle>Launched 2017</TastyContainerSubtitle>
                    <FlagIcon alt='Australian National Flag' src={ausFlagIconImg} />
                    <TastyContainerSubtitle>Launched 2019</TastyContainerSubtitle>
                  </IconContainer>
                  <TastyContainerText $type='body'>
                    From the brains behind tasty<em>live</em>, tastytrade believes in being
                    a better broker that helps traders take control of their money
                    with low rates, fast technology, and award-winning customer service.
                  </TastyContainerText>
                </div>
              </Col>
              <SmallPrint gutterTop>
                Any financial services provided in Australia are provided by or on behalf of tastytrade Australia under
                its Australian Financial Services License number 508867. The information has been prepared without
                considering your objectives, financial situation or needs. Consequently, you should consider the
                information in light of your objectives, financial situation and needs.
              </SmallPrint>
            </StyledRow>
          </HomeSection>
        </FullBleedSection>
        <HomeSection className='my-5'>
          <Row className='flex-column-reverse flex-lg-row align-items-center'>
            <Col xs={12} lg={9}>
              <h2 className='text-center text-lg-left text-uppercase'>Pricing</h2>
              <p>Our revolutionary low rates make for easier trading decisions. See how we compare against other brokers!</p>
            </Col>
            <Col xs={12} lg={3}>
              <SectionIcon src={pricingIcon} alt='Currency symbol' />
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col xs={12}>
              <AbbreviatedPricingTable />
              <div className='text-center mt-4'>
                <ExternalLink
                  as={TastyButton}
                  primary
                  displayUrl='http://tastytrade.com'
                  href='https://tastytrade.com/pricing/'
                >
                  Learn More About Our Pricing
                </ExternalLink>
              </div>
            </Col>
          </Row>
        </HomeSection>
        <HomeSection><hr /></HomeSection>
        <HomeSection className='my-5'>
          <Row className='mb-5 align-items-center'>
            <Col xs={12} lg={3}>
              <SectionIcon src={technologyIcon} alt='Zoomed in circuit board to symbolize technology' />
            </Col>
            <Col xs={12} lg={9}>
              <h2 className='text-center text-lg-left text-uppercase'>Technology</h2>
              <p>We've been innovating trading software since 2000 and we're not stopping now! You get the power to trade smarter.</p>
            </Col>
          </Row>
          <TechnologyFeatures />
          <Row className='mt-3'>
            <Col xs={12}>
              <div className='text-center mt-4'>
                <TastyButton to='/technology' primary>Learn More About Our Technology</TastyButton>
              </div>
            </Col>
          </Row>
        </HomeSection>
        <HomeSection><hr /></HomeSection>
        <HomeSection className='my-5'>
          <Row className='flex-column-reverse flex-lg-row mb-5 align-items-center'>
            <Col xs={12} lg={9}>
              <h2 className='text-center text-lg-left text-uppercase'>Education</h2>
              <p>Explore tasty<em>live's</em> free learning resources, research studies, and video archives to help you navigate the markets and find actionable trade ideas.</p>
            </Col>
            <Col xs={12} lg={3}>
              <SectionIcon src={educationIcon} alt='Brain with a lightning bolt, symbolizing education' />
            </Col>
          </Row>
          <EducationFeatures />
          <Row className='mt-3'>
            <Col xs={12}>
              <div className='text-center mt-4'>
                <EducationButton to='/education' primary>Learn More About Our Education</EducationButton>
              </div>
            </Col>
          </Row>
        </HomeSection>
        <FullBleedSection variant='light' className='mt-5 py-5'>
          <HomeSection className='text-center'>
            <h2>Are you held back by steep pricing, slow tech, and old investing ideas?</h2>
            <p>Open a tastytrade account today to start trading faster and smarter.</p>
            <TastyButton href={externalLinks.openAnAccount} primary>Open My Account</TastyButton>
          </HomeSection>
        </FullBleedSection>
      </main>
    </Layout>
  )
};

export default IndexPage;
