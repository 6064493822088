import desktopCarouselOne from 'images/carousel/TT1332_TW-MARK-D01-clean.png';
import desktopCarouselTwo from 'images/carousel/TT1332_TW-MARK-D02-clean.png';
import desktopCarouselThree from 'images/carousel/TT1332_TW-MARK-D03-clean.png';
import desktopCarouselFour from 'images/carousel/TT1332_TW-MARK-D04-clean.png';
import desktopCarouselFive from 'images/carousel/TT1332_TW-MARK-D05-clean.png';
import desktopCarouselSix from 'images/carousel/TT1332_TW-MARK-D06-clean.png';
import desktopCarouselSeven from 'images/carousel/TT1332_TW-MARK-D07-clean.png';
import desktopCarouselEight from 'images/carousel/TT1332_TW-MARK-D08-clean.png';
import desktopCarouselNine from 'images/carousel/TT1332_TW-MARK-D09-clean.png';
import desktopCarouselTen from 'images/carousel/TT1332_TW-MARK-D10-clean.png';
import desktopCarouselEleven from 'images/carousel/TT1332_TW-MARK-D11-clean.png';
import desktopCarouselTwelve from 'images/carousel/TT1332_TW-MARK-D12-clean.png';
import desktopCarouselThirteen from 'images/carousel/TT1332_TW-MARK-D13-clean.png';
import desktopCarouselFourteen from 'images/carousel/TT1332_TW-MARK-D14-clean.png';
import desktopCarouselFifteen from 'images/carousel/TT1332_TW-MARK-D15-clean.png';
import desktopCarouselSixteen from 'images/carousel/TT1394_TW-MARK-D01_r1-clean.png';
import desktopCarouselSeventeen from 'images/carousel/TT1394_TW-MARK-D02_r1-clean.png';
import desktopCarouselEighteen from 'images/carousel/TT1394_TW-MARK-D03_r1-clean.png';
import desktopCarouselNineteen from 'images/carousel/TT1355_TW-MARK-D01a-clean.png';
import desktopCarouselTwenty from 'images/carousel/TT1383_TW-MARK-D01a-clean.png';
import desktopCarouselTwentyOne from 'images/carousel/TT1394_TW-MARK-D01_r3-clean.png';
import desktopCarouselTwentyThree from 'images/carousel/TT1444_TW-MKD02a.png';
import desktopCarouselTwentyFour from 'images/carousel/TT1442_TW-MKD01a.png';
import desktopCarouselTwentyFive from 'images/carousel/TT1446_TW-MKD01_r1.png';
import desktopCarouselTwentySix from 'images/carousel/better-broker-desk-bkgrd.png';
import mobileCarouselOne from 'images/carousel/TT1332_TW-MARK-M01-clean.png';
import mobileCarouselTwo from 'images/carousel/TT1332_TW-MARK-M02-clean.png';
import mobileCarouselThree from 'images/carousel/TT1332_TW-MARK-M03-clean.png';
import mobileCarouselFour from 'images/carousel/TT1332_TW-MARK-M04-clean.png';
import mobileCarouselFive from 'images/carousel/TT1332_TW-MARK-M05-clean.png';
import mobileCarouselSix from 'images/carousel/TT1332_TW-MARK-M06-clean.png';
import mobileCarouselSeven from 'images/carousel/TT1332_TW-MARK-M07-clean.png';
import mobileCarouselEight from 'images/carousel/TT1332_TW-MARK-M08-clean.png';
import mobileCarouselNine from 'images/carousel/TT1332_TW-MARK-M09-clean.png';
import mobileCarouselTen from 'images/carousel/TT1332_TW-MARK-M10-clean.png';
import mobileCarouselEleven from 'images/carousel/TT1332_TW-MARK-M11-clean.png';
import mobileCarouselTwelve from 'images/carousel/TT1332_TW-MARK-M12-clean.png';
import mobileCarouselThirteen from 'images/carousel/TT1332_TW-MARK-M13-clean.png';
import mobileCarouselFourteen from 'images/carousel/TT1332_TW-MARK-M14-clean.png';
import mobileCarouselFifteen from 'images/carousel/TT1332_TW-MARK-M15-clean.png';
import mobileCarouselSixteen from 'images/carousel/TT1394_TW-MARK-M01_r1-clean.png';
import mobileCarouselSeventeen from 'images/carousel/TT1394_TW-MARK-M02_r1-clean.png';
import mobileCarouselEighteen from 'images/carousel/TT1394_TW-MARK-M03_r1-clean.png';
import mobileCarouselNineteen from 'images/carousel/TT1335_TW-MARK-M01a-clean.png';
import mobileCarouselTwenty from 'images/carousel/TT1383_TW-MARK-M01-clean.png';
import mobileCarouselTwentyOne from 'images/carousel/TT1394_TW-MARK-M01_r3-clean.png';
import mobileCarouselTwentyThree from 'images/carousel/TT1444_TW-MKM02a.png';
import mobileCarouselTwentyFour from 'images/carousel/TT1442_TW-MKM01.png';
import mobileCarouselTwentyFive from 'images/carousel/TT1446_TW-MKM01_r1.png';
import mobileCarouselTwentySix from 'images/carousel/better-broker-mobile-bkgrd.png';
import mobileCarouselTwentySeven from 'images/carousel/TWAUS-brand-banner-mobile.png';
import desktopCarouselTwentySeven from 'images/carousel/TWAUS-brand-banner-desktop.png';
import tabletCarouselTwentySeven from 'images/carousel/TWAUS-rebrand-banner-tablet.png'
import desktopCryptoKickbackLogo from 'images/TT1383_Logo01.png';
import mobileCryptoKickbackLogo from 'images/TT1383_Logo01.png';
import desktopCryptoMillionLogo from 'images/logo-desktop-crypto-million.png';
import mobileCryptoMillionLogo from 'images/logo-mobile-crypto-million.png';
import desktopCryptoLogo from 'images/TT1446_TW-MKD01_r1-header.png';
import mobileCryptoLogo from 'images/TT1446_TW-MKM01_r1-header.png';
import desktopTenOrOneHundredLogo from 'images/TT1444_TW-MKD02a-header.png';
import mobileTenOrOneHundredLogo from 'images/TT1444_TW-MKM02a-header.png';
import desktopThinkTraderLogo from 'images/carousel/TWAUS-trader-text.png';
import mobileThinkTraderLogo from 'images/carousel/TWAUS-trader-text-mobile.png';
import traderDayLogo from 'images/TT1442_TW-MK01-logo.png';
import externalLinks from 'utils/externalLinks';

export const groupOne = [
  {
    id: 'image1',
    desktopSrc: desktopCarouselOne,
    mobileSrc: mobileCarouselOne,
  },
  {
    id: 'image2',
    desktopSrc: desktopCarouselTwo,
    mobileSrc: mobileCarouselTwo,
  },
  {
    id: 'image3',
    desktopSrc: desktopCarouselThree,
    mobileSrc: mobileCarouselThree,
  }
];

export const groupTwo = [
  {
    id: 'image4',
    desktopSrc: desktopCarouselFour,
    mobileSrc: mobileCarouselFour,
  },
  {
    id: 'image5',
    desktopSrc: desktopCarouselFive,
    mobileSrc: mobileCarouselFive,
  },
  {
    id: 'image6',
    desktopSrc: desktopCarouselSix,
    mobileSrc: mobileCarouselSix,
  }
];

export const groupThree = [
  {
    id: 'image7',
    desktopSrc: desktopCarouselSeven,
    mobileSrc: mobileCarouselSeven,
  },
  {
    id: 'image8',
    desktopSrc: desktopCarouselEight,
    mobileSrc: mobileCarouselEight,
  },
  {
    id: 'image9',
    desktopSrc: desktopCarouselNine,
    mobileSrc: mobileCarouselNine,
  }
];

export const groupFour = [
  {
    id: 'image10',
    desktopSrc: desktopCarouselTen,
    mobileSrc: mobileCarouselTen,
  },
  {
    id: 'image11',
    desktopSrc: desktopCarouselEleven,
    mobileSrc: mobileCarouselEleven,
  },
  {
    id: 'image12',
    desktopSrc: desktopCarouselTwelve,
    mobileSrc: mobileCarouselTwelve,
  }
];

export const groupFive = [
  {
    id: 'image13',
    desktopSrc: desktopCarouselThirteen,
    mobileSrc: mobileCarouselThirteen,
  },
  {
    id: 'image14',
    desktopSrc: desktopCarouselFourteen,
    mobileSrc: mobileCarouselFourteen,
  },
  {
    id: 'image15',
    desktopSrc: desktopCarouselFifteen,
    mobileSrc: mobileCarouselFifteen,
  }
];

export const groupSix = [
  {
    id: 'image16',
    desktopSrc: desktopCarouselSixteen,
    mobileSrc: mobileCarouselSixteen,
  },
  {
    id: 'image17',
    desktopSrc: desktopCarouselSeventeen,
    mobileSrc: mobileCarouselSeventeen,
  },
  {
    id: 'image18',
    desktopSrc: desktopCarouselEighteen,
    mobileSrc: mobileCarouselEighteen,
  }
];

export const groupSeven = [
  {
    id: 'image20',
    desktopSrc: desktopCarouselTwentySeven,
    mobileSrc: mobileCarouselTwentySeven,
    tabletSrc: tabletCarouselTwentySeven,
    desktopLogoSrc: desktopThinkTraderLogo,
    mobileLogoSrc: mobileThinkTraderLogo,
    logoAlt: 'Think like a trader',
    ctaText: '',
    ctaLink: externalLinks.openAnAccount,
    ctaAria: 'Open a tastytrade account',
  },
];

export const groupEight = [
  {
    id: 'image20',
    desktopSrc: desktopCarouselTwentySeven,
    mobileSrc: mobileCarouselTwentySix,
    desktopLogoSrc: null,
    mobileLogoSrc: null,
    logoAlt: null,
    ctaText: '',
    ctaLink: externalLinks.openAnAccount,
    ctaAria: 'Open a tastytrade account',
  },
];
