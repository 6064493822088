import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop, theme } from 'styled-tools';
import { Col, Row as BaseRow } from 'reactstrap';
import ResponsiveEmbed from 'components/ResponsiveEmbed';

const Row = styled(BaseRow)`
  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    align-items: center;
  }
`;

const CircleText = styled.span`
  background-color: ${prop('theme.colors.primaryRed')};
  border: 0.15625rem dotted ${prop('theme.colors.white')};
  border-radius: 50%;
  color: ${prop('theme.colors.white')};
  display: inline-block;
  flex: 0 0 auto;
  font-weight: 700;
  height: 4.5rem;
  line-height: 4.5rem;
  margin-right: 0.7rem;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  width: 4.5rem;
`;

const InfoContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 2rem;
  text-transform: uppercase;

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin-right: auto;
    max-width: 20.625rem;
  }

  &:last-child {
    @media (min-width: ${prop('theme.breakpoints.lg')}) {
      margin-bottom: 0;
    }
  }
`;

const Description = styled.h4`
  font-weight: 700;
  margin-bottom: 0;
  padding: 0.5rem;
`;

function EducationFeatures() {
  return (
    <Row>
      <Col md={12} lg={5}>
        <InfoContainer>
          <CircleText>Videos</CircleText>
          <Description>
            Live Programming 7 Days a Week
          </Description>
        </InfoContainer>
        <InfoContainer>
          <CircleText>Learning</CircleText>
          <Description>
            Free courses for traders of all levels
          </Description>
        </InfoContainer>
        <InfoContainer>
          <CircleText>Research</CircleText>
          <Description>
            Trade ideas based on years of research
          </Description>
        </InfoContainer>
      </Col>
      <Col md={12} lg={7}>
        <ResponsiveEmbed>
          <iframe
            src="https://www.youtube.com/embed/jm0vCrRzBWU"
            title="YouTube video player"
            frameBorder="0"
            webkitAllowFullScreen
            mozallowfullscreen
            allowFullScreen
          />
        </ResponsiveEmbed>
      </Col>
    </Row>
  );
}

export default EducationFeatures;
