import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import ExternalLink from './ExternalLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronRight,
  faCaretUp,
  faCaretDown,
  faTimes,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';

library.add(faChevronRight, faCaretUp, faCaretDown, faTimes, faCircle);

const StyledGalleryCard = styled.a.attrs(props => ({
  as: (props.to && Link) || (props.displayUrl && ExternalLink) || (props.href && 'a'),
}))`
  text-decoration: none;
  margin: 0.6em 1.2em;

  &::before {
    content: url(${prop('hoverImage')});
    display: none;
  }

  &:hover {
    text-decoration: none;

    & > div {
      background-image: url(${prop('hoverImage')});

      & > div {
        display: block !important;;
      }
    }
  }

  /* Making sure trader appreciation card copy positioning matches the design */
  &:nth-child(2) {
    p {
      max-width: 52%;
    }
  }

  & > div {
    border: 0.625rem solid ${prop('theme.colors.primaryOffWhite')};
    background-image: url(${prop('image')});
    background-size: cover;
    background-position: center;
    position: relative;
    box-shadow: 0.1875rem 0.1875rem 0.58594rem rgba(0, 0, 0, 0.5);
    height: 12.5rem;
    width: 19.25rem;

    @media (min-width: ${prop('theme.breakpoints.md')}) and
    (max-width: ${prop('theme.breakpoints.lg')}) {
      background-image: url(${prop('hoverImage')});
      height: 23.5rem;
      width: 38.25rem;
    }

    @media (max-width: ${prop('theme.breakpoints.sm')}) {
      background-image: url(${prop('hoverImage')});
    }

    & > div {
      @media (min-width: ${prop('theme.breakpoints.md')}) and
      (max-width: ${prop('theme.breakpoints.lg')}) {
        display: block !important;
      }

      @media (max-width: ${prop('theme.breakpoints.sm')}) {
        display: block !important;
      }
    }
  }
`;

const CardCopy = styled.div`
  color: ${prop('theme.colors.black')};
  padding: 1rem;

  @media (min-width: ${prop('theme.breakpoints.md')}) and
  (max-width: ${prop('theme.breakpoints.lg')}) {
    padding: 2rem;
  }
`;

const CardHeader = styled.h3`
  font-family: ${prop('theme.fonts.default')};
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.75rem;
  margin-bottom: 0;

  @media (min-width: ${prop('theme.breakpoints.md')}) and
  (max-width: ${prop('theme.breakpoints.lg')}) {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
`;

const CardHeaderTwo = styled.h5`
  font-family: ${prop('theme.fonts.default')};
  font-size: 1.05rem;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 0;

  @media (min-width: ${prop('theme.breakpoints.md')}) and
  (max-width: ${prop('theme.breakpoints.lg')}) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
`;

const CardSubheader = styled.p`
  font-family: ${prop('theme.fonts.default')};
  font-size: 0.8125rem;
  letter-spacing: -0.03125rem;
  line-height: 1rem;
  margin-bottom: 0;
  max-width: 60%;

  @media (min-width: ${prop('theme.breakpoints.md')}) and
  (max-width: ${prop('theme.breakpoints.lg')}) {
    font-size: 1.6875rem;
    line-height: 1.6875rem;
    max-width: 66%;
  }
`;

const propTypes = {
  image: PropTypes.string.isRequired,
  hoverImage: PropTypes.string.isRequired,
  header: PropTypes.string,
  subheader: PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.string,
  to: PropTypes.string,
  rel: PropTypes.string,
  target: PropTypes.string,
  displayUrl: PropTypes.string,
};

function GalleryCard({
  image,
  hoverImage,
  href,
  to,
  children,
  header,
  subheader,
  rel,
  target,
  displayUrl
}) {
  // Preload hover image so it appears immediately on hover.
  useEffect(() => {
    /* global Image */
    const preloaded = new Image();
    // Setting src here triggers browser to load the image.
    preloaded.src = hoverImage;
  }, []);

  return (
    <StyledGalleryCard
      image={image}
      hoverImage={hoverImage}
      href={href}
      to={to}
      rel={rel}
      target={target}
      displayUrl={displayUrl}
    >
      <div>
        {children}
        {header &&
          <CardCopy>
            <CardHeader>{header}</CardHeader>
            <CardSubheader>{subheader}</CardSubheader>
          </CardCopy>
        }
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '2.5rem',
            height: '2.5rem',
            margin: 'auto',
            padding: '0.4rem 0.9375rem',
            textAlign: 'center',
            display: 'none',
            background: '#e2252a'
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} color='white' size='lg' />
        </div>
      </div>
    </StyledGalleryCard>
  )
}

GalleryCard.propTypes = propTypes;

export default GalleryCard;
